import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stopwatch from "../components/Stopwatch";
import Card from '@material-ui/core/Card';
import RoomDialog from "../components/RoomDialog";
import Dialog from '@material-ui/core/Dialog';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AssetGridCircles from '../components/AssetGridCircles';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import AssetDialog from "../components/AssetDialog";
import SoundClip from "../components/SoundClip";
import Switch from '@material-ui/core/Switch';



const useStyles = makeStyles({
  root: {
    minWidth: '150px',
    minHeight: '150px',
    border: '0.5px solid rgba(0, 0, 0)',
  },
  actionGrid: {
    backgroundColor: '#5BC236',
    align: 'center',
    borderRadius: '50%',
    textAlign: 'center',
    minHeight: '60px'
  },
  grid: {
    height: '80px',
    textAlign: 'center'
  }
});

const Timer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openFinish, setOpenFinishDialog] = React.useState(false);
  const [addAsset, setAddAsset] = React.useState(false);
  const currentTime = new Date();
  const timeDiff = new Date(props.endTime) - currentTime;
  const [duration, setDuration] = React.useState(timeDiff / 1000);
  const [myColor, setMyColor] = React.useState({ backgroundColor: 'white' })
  const [addedAssetList, setaddedAssetList] = React.useState("");
  const [play, setPlay] = useState({ state: false, data: '' });
  const [extended, setExtended] = React.useState(false);
  const [switchState, setSwitchState] = React.useState(!props.roomDisabled);

  const handleDialogClick = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleCloseFinish = () => {
    setOpenFinishDialog(false);
  }

  const addNewAsset = () => {
    setAddAsset(true);
    setaddedAssetList("");
  }

  const handleSwitchChange = (event) => {
    props.updateRoomStatus(props.roomId, switchState)
    setSwitchState(!switchState);
  };

  async function handleVoid(event) {
    event.preventDefault();
    props.setLoading(true);
    props.setPrice(0);
    setDuration("");
    const currentTime = new Date();

    let assetsInRoom = props.assetList.filter(it => props.roomName === it.tracking);
    await props.createTransactionDate(assetsInRoom, 0, duration, props.roomName, "Void", props.roomId);
    props.endRoomTime(currentTime, props.roomId);
    props.setLoading(false);
  }


  async function handleExtend() {
    let assetsInRoom = props.assetList.filter(it => props.roomName === it.tracking);
    await props.setSelectedAsset(assetsInRoom[0].assetId);
    setExtended(true);
    setOpen(true);
  }

  async function handleStop(event) {
    event.preventDefault();
    let assetsInRoom = props.assetList.filter(it => props.roomName === it.tracking);
    await props.createTransactionDate(assetsInRoom, props.price, duration, props.roomName, "Valid", props.roomId);
    setDuration("");
    props.setPrice(0);
    setMyColor({ backgroundColor: 'white' });
    const currentTime = new Date();
    await props.endRoomTime(currentTime, props.roomId);
    handleCloseFinish();
    setPlay({ state: false, data: Math.random() });
  }

  async function handleFinish(event) {
    event.preventDefault();
    setOpenFinishDialog(true)
  }

  return (
    <Card className={classes.root}>

      <CardContent style={myColor}>

        <Grid container>
          <Grid item xs={10} sm={10}>
            <Typography variant="h5" color="primary">
              {props.roomName}
              <Switch
                checked={switchState}
                onChange={handleSwitchChange}
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Typography>

          </Grid>
          {!switchState && <div style={{
            position: 'absolute', marginTop: '35px', minWidth: '220px', opacity: 0.9, minHeight: '60px',
            backgroundColor: "#f50057", color: '#fff', textAlign: 'center', fontSize: '28px', lineHeight: '125px', zIndex: 20
          }}> Unavailable</div>}

          <Grid item xs={2} sm={2}>
            <Typography variant="h5" color="textPrimary">
              {props.roomType}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.grid}>
          <Grid item xs={8} sm={8}>
            {props.assetList.filter(it => props.roomName === it.tracking).length > 0 ?
              <Stopwatch
                time={duration}
                setPlay={setPlay}
                myColor={myColor}
                setMyColor={setMyColor}
              />
              : <Typography color="textSecondary" align="left" variant="h3">--:--</Typography>
            }
          </Grid>

          <Grid item xs={4} sm={4}>
            {props.assetList.filter(it => props.roomName === it.tracking).length > 0 && props.assetList !== null ?
              <AssetGridCircles
                override={true}
                assetList={props.assetList.filter(it => props.roomName === it.tracking)}
                selectedAsset={props.selectedAsset}
                setSelectedAsset={props.setSelectedAsset}
              />
              :
              <Button onClick={handleDialogClick} className={classes.actionGrid}>
                <TouchAppIcon color="disabled" />
              </Button>

            }
          </Grid>

          {props.extend ?
            <Grid item xs={2} sm={2} style={{marginBottom:'1.4em'}}>
              <Typography style={{ backgroundColor: 'red', color: 'white', fontSize: '12px', width: '7em', textAlign: 'center' }}>
                {props.extend}
              </Typography>
            </Grid> : <Grid item xs={2} sm={2}></Grid>
          }
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              color="secondary"
              disabled={!props.assetList.filter(it => props.roomName === it.tracking).length > 0}
              onClick={handleFinish}
            > Finish </Button>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              color="primary"
              disabled={!props.assetList.filter(it => props.roomName === it.tracking).length > 0}
              onClick={handleVoid}
            > Void</Button>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              disabled={!props.assetList.filter(it => props.roomName === it.tracking).length > 0}
              onClick={handleExtend}
            > Extend</Button>
          </Grid>
        </Grid>
      </CardContent>
      <RoomDialog
        open={open && props.selectedAsset !== ""}
        onClose={setOpen}
        extended={extended}
        extendRoom={props.extendRoom}
        setPrice={props.setPrice}
        price={props.price}
        startTime={props.startTime}
        endTime={props.endTime}
        extendPrice={props.extendPrice}
        setSelectedAsset={props.setSelectedAsset}
        selectedAsset={props.selectedAsset !== "" && props.selectedAsset}
        updateTracking={props.updateTracking}
        assetList={props.assetList}
        assetId={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetId}
        tracking={props.roomName}
        packages={props.packages}
        updateTransactionEndTime={props.updateTransactionEndTime}
        roomId={props.roomId}
        createTransaction={props.createTransaction}
        setDuration={setDuration}
        updateRoom={props.updateRoom}
        setLoading={props.setLoading}
        addAsset={addNewAsset}
        setaddedAssetList={setaddedAssetList}
        addedAssetList={addedAssetList}
        assetName={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetName}
        content={props.selectedAsset !== "" ? (props.assetList[props.selectedAsset].assetName + " has chosen this room") : "No girl chosen"}
      />
      <AssetDialog
        open={addAsset}
        setaddedAssetList={setaddedAssetList}
        addedAssetList={addedAssetList}
        assetList={props.assetList.filter(it => "Available" === it.tracking && props.selectedAsset !== it.assetId)}
        setAddAsset={setAddAsset}
      />
      <Dialog
        fullWidth={true}
        maxWidth={'xs'}
        open={openFinish}
        onClose={handleCloseFinish}
      >
        <DialogTitle id="responsive-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm session has ended?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseFinish} color="primary">
            Cancel
          </Button>
          <Button onClick={handleStop} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <SoundClip play={play} />
    </Card>
  )
}

export default Timer;