import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import MainRoom from "./containers/MainRoom";
import Transactions from "./containers/Transactions";

export default function Routes({ appProps }) {

  return (
    <Switch>
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      {
        appProps.username === "admin" || appProps.username === "sawadee" ?
          <AuthenticatedRoute path="/" exact component={Transactions} appProps={appProps} />
          : <AuthenticatedRoute path="/" exact component={MainRoom} appProps={appProps} />
      }
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
