import React from 'react';
import Box from '@material-ui/core/Box';
import MenuDialog from "../components/MenuDialog";
import Typography from '@material-ui/core/Typography';
import AssetGridCircles from '../components/AssetGridCircles';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles({
    actionGrid: {
        border: '1px dashed rgba(0, 0, 0, 0.12)',
        backgroundColor: '#CD0000',
        minHeight: '80px',
        align: 'center',
        textAlign: 'center'
    },
    circleGrid: {
        minHeight: '80px',
        padding: '5px'
    },
    card: {
        padding: '15px',
        border: '1px solid rgba(0, 0, 0, 1)'
    },
    root: {
        width: '50px',
        height: '50px',
        marginLeft: '10px',
        display: 'inline-block',
        textAlign: 'right'
    }
});

const imageList = ['/images/mc.png', '/images/period.jpg', '/images/toilet.png', '/images/meal.png', '/images/off.png', '/images/call.png', '/images/makeup.png'];

export default function TopFields(props) {
    const classes = useStyles();
    const [menuItem, setMenuitem] = React.useState("");
    const [open, setOpen] = React.useState(false);

    function handleChange(evt) {
        evt.preventDefault();
        setMenuitem(evt.currentTarget.value);
        setOpen(true);
    };

    const content = props.menuList.map((key) =>

        <Grid key={props.menuList.indexOf(key)} container style={{ border: '1px solid rgba(0, 0, 0)', borderRadius: '4px', alignItems: 'center' }}>
            <Grid item xs={2} sm={2}>
                <Typography align="left" variant="h4" style={{ display: 'inline-block', marginLeft: '1rem', fontSize: '2rem' }} >{key}</Typography>
                <Avatar src={imageList[props.menuList.indexOf(key)]} style={{ marginLeft: '10px', display: 'inline-block', height: '2.2rem', width: '2.2rem' }} />
            </Grid>

            <Grid item xs={2} sm={8} style={{ borderLeft: '1px solid rgba(0, 0, 0)', minHeight: '80px' }}>
                <div key={props.menuList.indexOf(key)} className={classes.circleGrid}>
                    {props.assetList.length > 0 &&
                        <AssetGridCircles
                            override={false}
                            assetList={props.assetList.filter(it => key === it.tracking)}
                            tracking={key}
                            selectedAsset={props.selectedAsset}
                            setSelectedAsset={props.setSelectedAsset}
                        />
                    }
                </div>
            </Grid>
            <Grid item xs={2} sm={2} style={{ minHeight: '80px' }}>
                <Button
                    fullWidth
                    value={key}
                    id={key}
                    className={classes.actionGrid}
                    onClick={handleChange}
                >
                    <TouchAppIcon color="disabled" style={{ color: "#fff" }} fontSize="large" />
                    <Typography style={{ color: "#fff" }} variant="h6">Tap Here</Typography>
                </Button>
            </Grid>

            {/* </Grid> */}
        </Grid>
    );

    return (
        <Box pt={3}>
            <MenuDialog
                open={open && menuItem !== "" && props.selectedAsset !== ""}
                onClose={setOpen}
                updateTracking={props.updateTracking}
                setSelectedAsset={props.setSelectedAsset}
                createTransaction={props.createTransaction}
                createTransactionDate={props.createTransactionDate}
                selectedAsset={props.selectedAsset !== "" && props.selectedAsset}
                assetId={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetId}
                assetName={props.selectedAsset !== "" && props.assetList[props.selectedAsset].assetName}
                tracking={menuItem}
                content={props.selectedAsset !== "" && (props.assetList[props.selectedAsset].assetName + " has chosen to go " + menuItem + ".")}
            />
            <Grid container >
                {content}
            </Grid>
        </Box>
    );
}