import React from 'react';
import { Grid } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2962ff',
      contrastText: "#fff",
    },
  }
});

theme.overrides.MuiToggleButton = {
  root: {
    color: "#000",
    backgroundColor:"#fff",
    border: '1px solid rgba(0, 0, 0, 1)',
    '&$selected': {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    '&:hover': {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.contrastText,
    },
    "&:disabled": {
      border: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      backgroundColor: '#FF4081',
    },
    "&$selected&:hover": {
      border: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
  },
};

export default function AssetGridCircles(props) {

  const handleAlignment = (event, newAlignment) => {
    event.preventDefault();
    props.setSelectedAsset(newAlignment);
  };
  
  const content = props.assetList.map((key) =>
  <Grid key={props.assetList.indexOf(key)} item style={props.override ? { position: 'absolute', marginTop: 80 + props.assetList.indexOf(key) * 20, marginLeft: -10 + props.assetList.indexOf(key) * 30 } : {}}>
  <MuiThemeProvider theme={theme}>
        <ToggleButton
          key={props.assetList.indexOf(key)}
          style={props.override ? { backgroundColor: 'rgb(255, ' + 64 * (1 + 0.2 * props.assetList.indexOf(key)) + ', ' + 129 * (1 + 0.2 * props.assetList.indexOf(key)) + ')', fontWeight: '500', marginLeft: '0.2em', padding: '4px',fontSize: '1.5rem', minHeight: '60px', minWidth: '60px' } : { minWidth: '60px', marginLeft: '0.2em', padding: '4px', minHeight: '60px', fontWeight: '500', fontSize: '1.5rem' }}
          disabled={key.tracking !== props.tracking}
          onClick={handleAlignment}
          disableFocusRipple
          disableRipple
          selected={key.assetId === props.selectedAsset}
          value={key.assetId}
        > {key.assetName} <img src="/images/girl.png" /></ToggleButton>
      </MuiThemeProvider>
    </Grid>
  );
  return (
    <Grid>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>{content}</Grid>
    </Grid>
  );
}