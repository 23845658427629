const prod = {
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: "https://akfg8klxjf.execute-api.ap-southeast-1.amazonaws.com/dev",
    NAME: "api01a3cfb8"
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: "ap-southeast-1_Bbcn4ssLR",
    APP_CLIENT_ID: "n08al5ta0pdndbffic0gga3rg",
    IDENTITY_POOL_ID: "ap-southeast-1:421a0332-ea36-448f-92ae-1cea90b31d7d"
  }
};

// Default to dev if not set
const config = prod;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // PIN: "9999",
  ...config
};
