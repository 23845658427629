import React from "react";
import { Auth } from "aws-amplify";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormFields } from "../libs/hooksLib";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    alignItems: 'center',
    border: '1px solid lightgray',
    padding: '50px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },  
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));
 
export default function Login(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loginValidation, setLoginValidation]= React.useState(false);
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    password: ""
  });

  const handleToggle = () => {
    setOpen(!open);
  };
   
  const validateForm = () => {
    return fields.username.length > 0 && fields.password.length >  0;
  }
 
  async function handleSubmit(event) {
    event.preventDefault();
    setLoginValidation(false);
    try {
      await Auth.signIn(fields.username, fields.password).then(user => 
        props.setUsername(user.username)  
      ); 
    } catch (e) {
      setOpen(!open);
      setLoginValidation(true);
    }
  }
 
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>

        <Typography color="primary"  style={{fontWeight: 500}} variant="h4">
          Login
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <AccountBoxIcon color="disabled" />
              </InputAdornment>
              ),
            }}
            label="Username"
            name="username"
            autoComplete="username"
            onChange={handleFieldChange}
            value={fields.username}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <LockIcon color="disabled"/>
              </InputAdornment>
              ),
            }}
            value={fields.password}
            onChange={handleFieldChange}
            autoComplete="current-password"
          />   
          <div style={{height:'30px'}}>
            {loginValidation && 
              <Alert severity="warning">Incorrect username or password</Alert>
            }
          </div>
          <Button
            fullWidth
            type="submit"
            size="large"
            variant="contained"
            color="primary"
            disabled={!validateForm()}
            className={classes.submit}
            onClick={handleToggle}
          >
            Sign In
          </Button>
          <Backdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </form>
      </div>
    </Container>
  );
}