import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function AssetDialog(props) {

  const handleChange = (event) => {
    props.setaddedAssetList({
      ...props.addedAssetList,
      [event.target.name]: event.target.checked
    });
  };

  const handleClose = (event) => {
    event.preventDefault();
    props.setaddedAssetList("");
    props.setAddAsset(false);
  };

  async function handleConfirm(event) {
    event.preventDefault();
    props.setAddAsset(false);
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Select Additional Girl(s)"}</DialogTitle>
        <DialogContent>
          {props.assetList.map((key) =>
            <FormControlLabel key={key.assetName}
              control={<Checkbox checked={props.addedAssetList[key.assetName] ?? false}
                onChange={handleChange} name={key.assetName} />}
              label={key.assetName}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}