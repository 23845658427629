import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: '22px',
      }
    }
  }
});

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    minWidth: 120
  },
  cancelBtn: {
    backgroundColor: '#FF0000',
    color: '#fff'
  },
  startBtn: {
    backgroundColor: '#008000',
    color: '#fff'
  }
});

export default function RoomDialog(props) {
  const classes = useStyles();
  const [priceId, setPriceId] = React.useState('');


  const handleClose = (event) => {
    event.preventDefault();
    props.onClose(false);
  };

  const handleChange = event => {
    setPriceId(Number(event.target.value) || '');
  };

  async function startTimer(event) {
    event.preventDefault();
    if (priceId === "" || !props.packages.length > 0) return;

    const price = props.packages.filter(it => priceId === it.packageId)[0].price;
    const duration = props.packages.filter(it => priceId === it.packageId)[0].duration;
    const currentTime = new Date();

    if (props.extended) {
      props.setLoading(true);
      const startTime = new Date(props.startTime);
      const endTime = new Date(props.endTime);
      const newEndTime = new Date(endTime.getTime() + duration * 1000);
      const newPrice = price + props.price;
      const extendPrice = price + props.extendPrice;
      props.setDuration("");
      props.setDuration((newEndTime - currentTime) / 1000);
      await props.extendRoom(props.roomId, startTime, newEndTime, newPrice, extendPrice)
      props.onClose(false);
    } else {
      if (props.assetName === null || props.assetName === "") throw new Error("Girl not found");
      const endTime = new Date(currentTime.getTime() + duration * 1000).toISOString();
      props.setaddedAssetList("");
      props.setSelectedAsset("");
      props.setDuration(duration);
      props.setPrice(price);
      await props.updateTracking(props.assetId, props.tracking, price);

      if (props.addedAssetList !== "") {
        for (var asset in props.addedAssetList) {
          let addedAssetId = props.assetList.findIndex(filterName =>
            filterName['assetName'] === asset);

          if (props.addedAssetList[asset] === true) {
            await props.updateTracking(addedAssetId, props.tracking, price);
          }
        }
      }
      setPriceId('');
      await props.updateRoom(props.roomId, endTime, currentTime, price);
      props.setLoading(false);
      props.onClose(false);
    }
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Select Price"}</DialogTitle>
        <DialogContent>

          {props.packages.length > 0 ? <FormControl className={classes.formControl}>
            <MuiThemeProvider theme={theme}>
              <RadioGroup value={priceId} classes={{ root: classes.radio, checked: classes.checked }} onChange={handleChange}>
                <FormControlLabel className={classes.radioForm} disabled key='98' value='40' control={<Radio />}
                  label={"$" + 40 + " - " + 20 + " mins"} />
                <FormControlLabel className={classes.radioForm} disabled key='99' value='50' control={<Radio />}
                  label={"$" + 50 + " - " + 25 + " mins"} />
                {props.packages.map((key) =>
                  <FormControlLabel style={{ color: key.color }} key={key.duration} value={key.packageId} control={<Radio />}
                    label={"$" + key.price + " - " + key.duration / 60 + " mins"} />
                )}
              </RadioGroup>
            </MuiThemeProvider>
          </FormControl> :
            <Typography>Error</Typography>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus disabled={priceId === ""} onClick={props.addAsset} color="primary">
            Add More Girl
          </Button>
          <Button autoFocus onClick={handleClose} className={classes.cancelBtn}>
            Cancel
          </Button>
          <Button color="primary" onClick={startTimer} className={classes.startBtn} disabled={props.selectedAsset === ""} autoFocus>
            {props.extended ? "Extend" : "Start"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}