import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles({
  root: {
    minWidth: '200px',
    minHeight: '200px',
  },
  actionGrid: {
    backgroundColor: '#5BC236',
    align: 'center',
    minHeight: '50px'
  },
  grid: {
    height: '145px',
  }
});

const LoadingTimer = (props) => {
  const classes = useStyles();
  const [myColor] = React.useState({ backgroundColor: 'white' })

  return (
    <Card className={classes.root}>
      <CardContent style={myColor}>
        <Grid container>
          <Grid item xs={10} sm={10}>
            <Typography variant="h5" color="primary">
              {props.roomName}
              <Switch
                checked
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Typography>
          </Grid>

          <Grid item xs={2} sm={2}>
            <Typography variant="h5" color="textPrimary">
              {props.roomType}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignContent="center" className={classes.grid}>
          <Grid item xs={8} sm={8}>
            <Typography color="textSecondary" align="left" variant="h3">--:--</Typography>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button className={classes.actionGrid}>
              <TouchAppIcon color="disabled" />
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              color="primary"
              disabled
            > Finish </Button>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              color="primary"
              disabled
            > Void</Button>
          </Grid>
          <Grid item xs={4} sm={4}>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              color="primary"
              disabled
            > Extend</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default LoadingTimer;