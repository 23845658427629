import React from "react";
import MaterialTable from "material-table";

export default function Table (props) {
  
  const addTotal = (data, priceCol, taiCol, endTime) => {
    let keys = Object.keys(data[0]);
    let totalPrice = data.reduce((acc, el) => {
      return acc += +(el[priceCol]);
    }, 0);

    let totalTai = data.reduce((acc, el) => {
      return acc += +(el[taiCol]);
    }, 0);

    let totalRow = {};

    for (let key of keys) {
      if (key === endTime) {
        totalRow[key] = 'Total';
      } else if (key === priceCol) {
        totalRow[key] = totalPrice;
      } else if (key === taiCol) {
        totalRow[key] = totalTai;
      } else {
        totalRow[key] = '';
      }
    }
    return [totalRow];
  }

  return (
    <div style={{ maxWidth: "100%", paddingTop: "15px" }}>
      <MaterialTable
        title={ props.assetName + " from " + props.startDateString + " to " + props.endDateString}
        columns={[
          { title: "Girl", field: "assetName", filtering: false},
          { title: "Start Date & Time", field: "startTime", type:"datetime", filtering: false},
          { title: "End Date & Time", field: "endTime", type:"datetime", filtering: false},
          { title: "Price ($)", field: "price", type: "numeric", filtering: false},
          { title: "Tai", field: "tai", type: "numeric", filtering: false},
          { title: "Tracking", field: "tracking" },
          { title: "Status", field: "status" },
        ]}
        data={props.tableData}  
        options={{
          headerStyle: {
            fontWeight: 800,
          },
          paging: false,
          exportButton: true,
          search:false,
          filtering: true
        }}
      />
      <MaterialTable
        title={""}
        columns={[
          { title: "", field: "assetName" },
          { title: "", field: "startTime", type:"datetime"},
          { title: "", field: "endTime", type:"datetime" },
          { title: "", field: "price", type: "numeric" },
          { title: "", field: "tai", type: "numeric" },
          { title: "", field: "tracking" },
          { title: "", field: "status" },
        ]}
        data={addTotal(props.tableData, "price", "tai", "endTime")}  
        options={{
          rowStyle: {
            backgroundColor: '#EEE',
            fontWeight: 800,
          },
          headerStyle: {
            color: '#fff',
          },
          paging: false,
          search:false,
          sorting:false,
          toolbar: false,
          header: false,
          exportButton: false,
        }}
      />
    </div>
  );
}
