import React , { useEffect } from 'react';

export default function SoundClip (props) {
  
  useEffect(() => {
    const audioEl = document.getElementById("myAudio");
    if(props.play.state) {
      try {audioEl.play();}
      catch(e) { console.log(e);}
    } else {
      try {audioEl.pause();}
      catch(e) { console.log(e);}
    }
  }, [props.play]);
      
  return (
    <div>
      <audio id="myAudio">
        <source src="/soundclip.wav" type="audio/wav"></source>
      </audio>
    </div>
  ) 
}